<!--
  This component displays a form to enter the user's email address
  in order to send him/her an email to recover the password.
-->
<template>
  <CContainer fluid class="login-common text-center">
    <CRow class="header">
      <img src="~@/assets/img/logo-nissan-white.png" alt="logo-nissan" />
    </CRow>
    <CRow align-horizontal="center">
      <img src="~@/assets/img/logo-login.png" class="logo" />
    </CRow>

    <div v-show="!success">
      <CRow align-horizontal="center">
        <CCol sm="8" md="8" lg="6" xxl="6">
          <h3 class="title">{{ messages.passwordRecovery.title }}</h3>
        </CCol>
      </CRow>
      <CRow align-horizontal="center">
        <CCol sm="6" md="4" lg="3" xxl="2">
          <p class="instructions">
            {{ messages.passwordRecovery.instructions }}
          </p>
        </CCol>
      </CRow>
      <CRow align-horizontal="center">
        <CCol sm="6" md="4" lg="3" xxl="2">
          <CForm @submit.prevent="handleRecoverPassword" novalidate="true">
            <CInput
              :placeholder="messages.emailPlaceholder"
              type="email"
              v-model="email"
              required
            />
            <p v-if="!isValidEmail && !!emailValidated" class="error-message">
              {{ messages.validation.emailError }}
            </p>
            <p
              v-if="errorBlocks.emailRequired && email.length === 0"
              class="error-message"
            >
              {{ messages.validation.required }}
            </p>

            <CButton type="submit" color="primary" :block="true">{{
              messages.passwordRecovery.button
            }}</CButton>
          </CForm>
          <ErrorMessage
            v-if="errorManager !== null"
            :message="errorManager.message"
            :styleClass="errorManager.styleClass"
          />
        </CCol>
      </CRow>
    </div>
    <div v-show="success">
      <CRow align-horizontal="center">
        <CCol sm="6" md="4" lg="3" xxl="3">
          <p v-if="success" class="success-message">
            {{ messages.passwordRecovery.successMessage }}
          </p>
        </CCol>
      </CRow>
    </div>
  </CContainer>
</template>

<script>
const ErrorMessage = () =>
  import(/* webpackChunkName: "error-message" */ "@/components/atoms/ErrorMessage");
import languages from "@/languages/languages.js";
import validation from "@/utils/validation";
import LoginAndPassword from "@/services/LoginAndPassword";
import langStr from "@/utils/langStr";

export default {
  name: "PasswordRecovery",
  components: {
    ErrorMessage,
  },
  data() {
    return {
      email: "",
      emailValidated: false,
      error: true,
      success: false,
      languages: languages,
      lang: "",
      errorBlocks: {
        emailRequired: false,
        emailNonExists: false,
      },
      errorManager: null,
    };
  },
  methods: {
    /**
     *  Email validation using validation utility
     */
    formValidation(obj) {
      if (!validation.rules().required(obj.email)) {
        this.errorBlocks.emailRequired = true;
      }
      if (!this.isValidEmail || !validation.rules().required(obj.email)) {
        return false;
      } else {
        return true;
      }
    },
    /**
     * Retrieve email and language to send to the API
     * Manage errors
     */
    handleRecoverPassword() {
      this.emailValidated = true;
      const recoverQuery = {
        email: this.email,
        languageId: langStr.getId(this.lang),
      };

      if (this.formValidation(recoverQuery)) {
        const queryUrl = `validateEmail/${this.email}`;
        LoginAndPassword()
          .get(queryUrl)
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              if (response.data.code === 200) {
                LoginAndPassword()
                  .post("forgotPassword", recoverQuery)
                  .then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                      if (response.data.code === 200) {
                        this.success = true;
                      } else {
                        // Manage error
                        const messages = this.languages.texts();
                        this.errorManager = {
                          message: `${
                            messages[this.lang].errors[response.data.code]
                          }`,
                          styleClass: "error-message error-message--bottom",
                        };
                      }
                    } else {
                      const messages = this.languages.texts();
                      this.errorManager = {
                        message: `${
                          messages[this.lang].errors[response.status]
                        }`,
                        styleClass: "error-message error-message--bottom",
                      };
                    }
                  });
              } else {
                // Manage error
                const messages = this.languages.texts();
                this.errorManager = {
                  message: `${messages[this.lang].errors[response.data.code]}`,
                  styleClass: "error-message error-message--bottom",
                };
              }
            }
          });
      }
    },
  },
  computed: {
    messages() {
      return this.languages.texts(this.lang);
    },
    isValidEmail() {
      return validation.rules().emailStrict(this.email);
    },
    isEmpty(value) {
      return validation.rules().required(value);
    },
  },
  created() {
    /**
     * Choosing the language according the path
     */
    if (this.$router.currentRoute.path === "/recuperar-contrasena") {
      this.lang = "es";
      this.$store.dispatch("saveCurrentLang", "es");
    } else if (this.$router.currentRoute.path === "/recuperar-contrasenha") {
      this.lang = "pt";
      this.$store.dispatch("saveCurrentLang", "pt");
    }
  },
};
</script>
